import { useEffect, useRef } from 'react';

type CallbackFunction = (...args: any[]) => void;

function useDebouncedCallback(callback: CallbackFunction, delay: number) {
  const argsRef = useRef<any[] | null>(null);
  const timeout = useRef<NodeJS.Timeout | null>(null);

  function cleanup() {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  }

  useEffect(() => cleanup, []);

  return function debouncedCallback(
    ...args: any[]
  ) {
    argsRef.current = args;

    cleanup();

    timeout.current = setTimeout(() => {
      if (argsRef.current) {
        callback(...argsRef.current);
      }
    }, delay);
  };
}

export default useDebouncedCallback;
